import React, { useState, useContext, useEffect, useRef } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { resetPassword, changePasswordWithToken, login, addToAccount, pushConcurrent, routeToInterface } from '../helpers/auth';
import { SET_SPINNER_VISIBLE } from '../reducer';
import { Context } from '../Store';

function Password(props) {
	var history = useHistory();
	const [appState, dispatch] = useContext(Context);
	const [state, setState] = useState({ isForgotPassword: false, touched: false, err: '', view: 'password', status: '', valid: false, focus: false, password: '' });
	const [addSuccess, setAddSuccess] = useState(false);

	var { username, email } = appState || {};

	useEffect(() => {
		passwordField.current.focus();
	}, []);

	const passwordField = useRef();

	function setView(view) {
		setState({ ...state, view });
	}

	function updateViaSecurityAnswers() {
		var userId = appState.userId;
		var answer1 = appState.securityAnswer1;
		var answer2 = appState.securityAnswer2;
		this.API.resetPasswordWithAnswers(userId,
			{ new_pw: this.user.password, security_a_1: answer1, security_a_2: answer2 }).then(
			(response) => {
				if (response.status === 'success') {
					getLocalStorageUsernameAndEmail();
					// dispatch({ type: SET_PASSWORD, action: state.password });
					setState({ ...state, view: 'success' });
					// login user automagically
					history.push('/user/complete');
				} else {
					setView('errorSecurityQuestions');
				}
			},
			(error) => {
				setView('error');
			}
		);
	}

	function getLocalStorageUsernameAndEmail() {
		if (username || email) return;
		username = localStorage.getItem('username');
		email = localStorage.getItem('email');
		dispatch({ type: 'SET_USERNAME', payload: username });
		dispatch({ type: 'SET_EMAIL', payload: email });
		localStorage.clear();
	}


	function updateViaToken() {
		var payload = {
			pw: this.user.password,
			token: this.token,
			wrap_output: true
		};

		changePasswordWithToken(payload).then(
			(response) => {
				if (response.status === 'success') {
					getLocalStorageUsernameAndEmail();

					var thisUName = username;

					if (thisUName === '' || thisUName === null) {
						dispatch({ type: 'SET_USERNAME', payload: username });
						dispatch({ type: 'SET_EMAIL', payload: email });
					}

					// dispatch({ type: SET_PASSWORD, action: state.password });
					setView('success');
					// login user automagically
					history.push('/user/complete');
				} else {
					setView('error');
				}
			},
			(error) => {}
		);
	}

	function updateStudent() {
	}

	function handleBack() {
		history.push('/user/username');
	}

	function forgotPassword() {
		setState({ ...state, isForgotPassword: true });
		if (username && username.indexOf('@') === -1) {
console.log('route to /auth/user/security');
			history.push('/forgot-password');
//      this.router.navigate(['/auth/user/security']);
		} else {
console.log('route to /auth/user/reset');
			history.push('/user/reset');
/*
      resetPassword(username).then(res => {
        console.log('resetPassword', res);
//      this.router.navigate(['/auth/user/reset']);
      });
*/
		}
	}

	function validCredentials(data) {
		routeToInterface().then(res => {
			if (res.assessment_app) {
				// BACKENDQA AD HOC
				if (!/qa-api/.test(res.url)) {
					window.location.href = res.url;
				} else {
					let backendqaUrl = res.url.replace(/next-react/, 'backendqa');
					backendqaUrl = backendqaUrl.replace(/qa-api/, 'qa1');
					window.location.href = backendqaUrl;
				}

			} else {
				window.location.href = res.url;
			}
		});
	}

	function invalidCredentials(data) {
		setState({ ...state, status: 'error' });
		setTimeout(() => {
			let el = document.querySelector('.wcag-err');
 			console.log('wcag-msg', el);
			if (el) { el.focus(); }
		}, 0);
	}


	//------------------------------------------------------------
    // If login response includes concurrent_session_ids, use them
    // to generate websocket push notifications, to signal the
    // assessment app to terminate these sessions.
    // This is initially for dbts accounts and goes with the
    // card that has all the other dbts stuff.
	//------------------------------------------------------------
	function signalConcurrent(status) {
		var { user_id, concurrent_session_ids } = status;
		if (Array.isArray(concurrent_session_ids)) {
			concurrent_session_ids.forEach(sess_id => {
				console.log('Log out session ID', user_id, sess_id);
				pushConcurrent(user_id, sess_id);
			});
		}
	}

	function registrationAddToAccount(payload) {
		addToAccount(payload).then(res => {
			setAddSuccess(true);
			dispatch({ type: SET_SPINNER_VISIBLE, payload: false });
			registrationLogin(payload);
		}, err => {
			dispatch({ type: SET_SPINNER_VISIBLE, payload: false });
			console.log('handleSubmit addToAccount err', err);
			setState({ ...state, status: 'error' });
		});
	}

	function registrationLogin(payload) {
		login(payload.email, payload.pw).then(res => {
			console.log('login session ID', res.session_id);
			if (res.concurrent_session_ids) {
				signalConcurrent(res);
			}
			dispatch({ type: SET_SPINNER_VISIBLE, payload: false });
			if (!res.status) {
				validCredentials(res);
			} else {
				invalidCredentials(res);
			}
		}, err => {
			dispatch({ type: SET_SPINNER_VISIBLE, payload: false });
			console.log('handleSubmit login err', err);
			setState({ ...state, status: 'error' });
		});
	}

	function handleSubmit(e) {
		e.preventDefault();
		dispatch({ type: SET_SPINNER_VISIBLE, payload: true });
		if (!state.password) {
			var err = <span>Error: Your password and username don't match.<br/>
			Please make sure you entered the correct username and password.</span>;
			setState({ ...state, focus: true, valid: false, err });
		} else {
			// username || email. Any reason these shouldn't be the same variable?
			var payload = { email: username || email, pw: state.password, wrap_output: true };
			if (appState.addToAccount && !addSuccess) {
				console.log('Add to account');
				registrationAddToAccount({ ...payload, token: appState.code });
			} else {
				registrationLogin(payload);
			}
		}
	}

	function updatePassword(e) {
		var password = e.target.value;
		setState({ ...state, password });
	}

	// The idea is to detect when the password field loses focus and to set an error if no password was entered.
	// The hope is that moving the message out of the HTML code will satisfy one of the WCAG requirements:
	// "Provide a consistent implementation of error and alert mechanisms"
	// The specific issue this is intended to fix:
	// "The form fields announce that they are "invalid entry" as soon as the user focuses on them.""
	// UPDATE: Moved error message from here up to handleSubmit. This prevents brief display of error if Forgot password? is clicked.
	function checkEmpty(focused) {
		var status = '';
		var focus = focused;
		var valid = state.isForgotPassword || (focus || !focus && state.password);
		setState({ ...state, focus, valid, status });
	}


	// Maintain username as it's entered
	function updatePassword(e) {
		var password = e.target.value;
		var touched = true;
		setState({ ...state, password, touched });
	}


  function selectView() {
    var jsx;
    switch (state.view) {
      case 'password':
        jsx = (
      <div className="form-group">
        { state.status === 'errorNoBook' && <h4 tabIndex="0" className="wcag-err">Sorry, but you don&apos;t have access to any books.<br/>Please contact your teacher.</h4> }
        { state.status === 'errorCouldNotAdd' && <h4 tabIndex="0" className="wcag-err">Sorry, but we were unable to add the registration code to your account.<br/>Please contact your teacher.</h4> }
        { state.status === 'regSuccess' && <h4 tabIndex="0" className="wcag-err">Registration Successful</h4> }
        { state.status === 'error' && <h4 tabIndex="0" className="wcag-err">Error: Your password and username don&apos;t match.<br/>Please make sure you entered the correct username and password.</h4> }

        {/*
        <!-- Added position:absolute; top: 120px to hold the error message position in place, so that the Forgot Password? link
             wouldn't shift down when the password field was blurred. This was causing the link click to not be detected the first time. -->
        */}
        { !state.valid && (<div style={{position: 'absolute', top: '120px'}} className="error-message">
          <div className="validation-message">
            <span aria-live="polite">{state.err}</span>
          </div>
        </div>) }

        <label className="font-weight-normal" htmlFor="password">Password:</label>
        <input
          ref={passwordField}
          value={state.password}
          onChange={updatePassword}
          name="password"
          type="password"
          className="form-control border-width-1px"
          id="password"
          placeholder="Enter your password"
          onFocus={() => { checkEmpty(true) } }
          onBlur={() => { checkEmpty(false) } }
          />
        { !state.valid && state.touched && <span className="input-error pull-right glyphicon glyphicon-remove"></span> }
        { state.valid && state.password && <span className="input-valid pull-right glyphicon glyphicon-ok"></span> }
          <button type="button" className="btn accessible-btn-info" onClick={forgotPassword}>Forgot password?</button>

        <div className="fix-bottom">
          <button type="button" onClick={handleBack} className="btn accessible-btn-default">Back</button>
          { (!state.valid || !state.password) && <button disabled="disabled" onClick={handleSubmit} type="submit" className="btn accessible-btn-primary pull-right">Next</button> }
          { (state.valid && state.password) && <button onClick={handleSubmit} type="submit" className="btn accessible-btn-primary pull-right">Next</button> }
        </div>
      </div>
        );
    };
    return jsx;
  }

  return (
      <main>
        <form onSubmit={handleSubmit}>
        {selectView()}
        </form>
      </main>
  );
}

export default withRouter(Password);
