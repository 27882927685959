import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { isDBTS, isSample } from '../helpers/config';
import { SET_CODE, SET_STUDENT_RESET } from '../reducer';
import { Context } from '../Store';

const titleClassNames = ['bold', 'wcag-h2-mimic-h3'];
if (isDBTS) {
    titleClassNames.push('dbts-title');
}

function Main() {
  var history = useHistory();
  const [appState, dispatch] = useContext(Context);

  function handleUseACode() {
    dispatch({ type: SET_STUDENT_RESET, payload: false });
    dispatch({ type: SET_CODE, payload: '' });
    history.push('/code');
  }

  function handleSignIn() {
    dispatch({ type: SET_STUDENT_RESET, payload: false });
    dispatch({ type: SET_CODE, payload: '' });
    history.push('/user/username');
  }


  const Buttons = isSample ? (
    <div>
      <button className="btn accessible-btn-warning" onClick={handleSignIn}>Sign in</button>
    </div>
  ) : (
    <div>
      <button className="btn accessible-btn-default" onClick={handleUseACode}>Use a code</button>
      <button className="btn accessible-btn-warning" onClick={handleSignIn}>Sign in</button>
    </div>
  );


  return (
            <main>
            <div className="text-center">

	  {isDBTS ? (
              <h2 className={titleClassNames.join(' ')}>The Power of Personalized Learning</h2>
	  ) : isSample ? (
              <h2 className={titleClassNames.join(' ')}>Digital Product Samples</h2>
	  ) : (
              <h2 className={titleClassNames.join(' ')}>Engage &middot; Collaborate &middot; Learn</h2>
      ) }
	          <br/>
             { Buttons }
            </div>
            </main>
  );
}

export default Main;
