import React, { useState, useContext, useEffect, useRef } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { setSecurityQuestions, resetPassword, login } from '..//helpers/auth';
import { Context } from '../Store';

const VALID_QUESTIONS = ['Who is your favorite teacher?', 'How many family members do you have?', 'What is your favorite food?'];


function SecurityCreate() {
  const [appState, dispatch] = useContext(Context);
  const [state, setState] = useState({ num: 0, view: 'answer', selectedQuestion: VALID_QUESTIONS[0],  answer: '', selectedQuestions: [], answers: [], questionPool: VALID_QUESTIONS });
  var history = useHistory();

  useEffect(() => {
    answerField.current.focus();
  })

  const answerField = useRef();

  function handleSelect(e) {
    var el = e.target;
    var question = el.value;
    
    setState({ ...state, selectedQuestion: question });
  }

  function handleChange(e) {
    var el = e.target;
    setState({ ...state, answer: el.value });
  }

  function handleFocus(e) {
     setState({ ...state, focus: true });
  }

  function handleBlur(e) {
     setState({ ...state, focus: false });
  }

  function removeQuestionFromPool(q) {
    var pool = state.questionPool;
    var ndx = pool.findIndex(p => p === q);
    pool.splice(ndx, 1);
    setState({ ...state, questionPool: pool });
  }

  function handleClick(e) {
    e.preventDefault();
    var { num, selectedQuestion, selectedQuestions, answer, answers, questionPool } = state;

    if (num <= 1) {
      removeQuestionFromPool(selectedQuestion);
      selectedQuestions.push(selectedQuestion);
      answers.push(answer);
      let newState = { ...state, selectedQuestions, answers, selectedQuestion: state.questionPool[0], answer: '' };
      if (num === 0) {
        newState.num = 1;
      }
      setState(newState);
      if (num === 1) {
        save().then(res => {
          history.push('/user/complete');
        });
      }
    }
  }


  function save() {
    var payload = {
      security_q_1: state.selectedQuestions[0],
      security_a_1: state.answers[0],
      security_q_2: state.selectedQuestions[1],
      security_a_2: state.answers[1]
    };

    return setSecurityQuestions(payload).then(
      (response) => {
        return true;
      },
      (error) => console.log(error)
    );

  }

  return (
    <form>
      <div className="form-group">
        <label className="font-weight-normal" htmlFor="securityQuestion">Question {state.num + 1}</label>
        <select name="securityQuestion" className="form-control" onChange={handleSelect}>
          { state.questionPool.map((q, ndx) => <option key={ndx} value={q}>{q}</option>) }
        </select>
      </div>
      <div className="form-group">
        <input
            name="answer"
            ref={answerField}
            type="text"
            className="form-control border-width-1px"
            id="securityQuestion"
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={state.answer}
            onChange={handleChange}
            placeholder="You'll use your answer if you forget your password" />
        { !state.focus && !state.valid && state.touched && <span
            className="input-error pull-right glyphicon glyphicon-remove"></span> }
		{ state.valid && <span
            className="input-valid pull-right glyphicon glyphicon-ok"></span> }
		<div className="validation-message">
            { !state.focus && !state.valid && state.touched && <span aria-live="polite">Error: You must choose a question to answer.</span> }
        </div>
        <div className="fix-bottom">
            { (!state.valid || !state.selectedQuestion) && <button onClick={handleClick} type="submit" className="btn accessible-btn-primary pull-right">Next</button> }
        </div>
      </div>
    </form>
  );
};

export default withRouter(SecurityCreate);
