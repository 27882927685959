export const SET_USERNAME = 'SET_USERNAME';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_AUTOLOGOUT = 'SET_AUTOLOGOUT';
export const SET_STUDENT_RESET = 'SET_STUDENT_RESET';
export const SET_CODE = 'SET_CODE';
export const SET_ORDER = 'SET_ORDER';
export const SET_IS_TEACHER_EDITION = 'SET_IS_TEACHER_EDITION';
export const SET_CREATE_STUDENT_ON_FLY = 'SET_CREATE_STUDENT_ON_FLY';
export const SET_IS_STUDENT_REGISTRATION_CODE = 'SET_IS_STUDENT_REGISTRATION_CODE';
export const SET_COURSE_ID = 'SET_COURSE_ID';
export const SET_COURSE_NAME = 'SET_COURSE_NAME';
export const SET_SCHOOL = 'SET_SCHOOL';
export const SET_USER_CREATE = 'SET_USER_CREATE';
export const SET_ADD_TO_ACCOUNT = 'SET_ADD_TO_ACCOUNT';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_SECURITY_ANSWER_1 = 'SET_SECURITY_ANSWER_1';
export const SET_SECURITY_ANSWER_2 = 'SET_SECURITY_ANSWER_2';
export const SET_FROM_REDIRECT = 'SET_FROM_REDIRECT';
export const SET_SPINNER_VISIBLE = 'SET_SPINNER_VISIBLE';

const Reducer = (state, action) => {
  switch (action.type) {
    case SET_USERNAME:
      return {
        ...state,
        username: action.payload
      };
      break;
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload
      };
      break;
    case SET_PASSWORD:
      return {
        ...state,
        password: action.payload
      };
      break;
    case SET_AUTOLOGOUT:
      return {
        ...state,
        autologout: action.payload
      };
      break;
    case SET_STUDENT_RESET:
      return {
        ...state,
        studentReset: action.payload
      };
      break;
    case SET_CODE:
      return {
        ...state,
        code: action.payload
      };
      break;
    case SET_ORDER:
      return {
        ...state,
        order: action.payload
      };
      break;
    case SET_IS_TEACHER_EDITION:
      return {
        ...state,
        isTeacherEdition: action.payload
      };
      break;
    case SET_CREATE_STUDENT_ON_FLY:
      return {
        ...state,
        createStudentOnFly: action.payload
      };
      break;
    case SET_IS_STUDENT_REGISTRATION_CODE:
      return {
        ...state,
        isStudentRegistrationCode: action.payload
      };
      break;
    case SET_COURSE_ID:
      return {
        ...state,
        courseId: action.payload
      };
      break;
    case SET_COURSE_NAME:
      return {
        ...state,
        courseName: action.payload
      };
      break;
    case SET_SCHOOL:
      return {
        ...state,
        school: action.payload
      };
      break;
    case SET_USER_CREATE:
      return {
        ...state,
        userCreate: action.payload
      };
      break;
    case SET_ADD_TO_ACCOUNT:
      return {
        ...state,
        addToAccount: action.payload
      };
      break;
    case SET_USER_ID:
console.log('SET_USER_ID', action.payload);
      return {
        ...state,
        userId: action.payload
      };
      break;
    case SET_SECURITY_ANSWER_1:
      return {
        ...state,
        securityAnswer1: action.payload
      };
      break;
    case SET_SECURITY_ANSWER_2:
      return {
        ...state,
        securityAnswer2: action.payload
      };
      break;
    case SET_FROM_REDIRECT:
      return {
        ...state,
        fromRedirect: action.payload
      };
      break;
    case SET_SPINNER_VISIBLE:
      return {
        ...state,
        spinnerVisible: action.payload
      };
      break;
    default:
      return state;
  };
}

export default Reducer;
