import React, { useState, useContext, useEffect, useRef } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { checkRegcode, getSchoolInfoById } from '../helpers/auth';
import { Context } from '../Store';
import { SET_SPINNER_VISIBLE, SET_CODE, SET_STUDENT_RESET, SET_ORDER, SET_IS_TEACHER_EDITION, SET_CREATE_STUDENT_ON_FLY, SET_IS_STUDENT_REGISTRATION_CODE, SET_COURSE_ID, SET_COURSE_NAME, SET_SCHOOL } from '../reducer';

function RegCode() {
  var history = useHistory();
  const [appState, dispatch] = useContext(Context);
  const [state, setState] = useState({ touched: false, err: '', view: 'regcode', status: '', valid: false, focus: false, regcode: '' });

  // Focus input field on load.
  useEffect(() => {
    regcodeField.current.focus();
  }, []);

  const regcodeField = useRef();

  function createTeacher(code, data) {
    var result = false;
    // check for is_te flag or 16-digit code.
    if (!!parseInt(data.is_te, 10)) {
      result = true;
    } else if (/^\d{16}$/.test(code)) {
      result = true;
    }

    return result;
  };

  // Test to see if new student should be created on the fly, given registration code and email address.
  function createStudentOnFly(data) {
    var result = data.course_id || !data.is_te;

    return result;
  };

  // Handle Back button
  function handleBack() {
    history.push('/');
  }

  // Handle Next button
  function handleNext(e) {
    e.preventDefault();
    dispatch({ type: SET_SPINNER_VISIBLE, payload: true });
    checkRegcode(state.regcode).then(res => {
      dispatch({ type: SET_CODE, payload: state.regcode });
      if (res.Code === 'NOT_FOUND' || res.hasOwnProperty('sourced_id') && res.sourced_id > '') {
        // If this isn't a registration code, treat as a password reset.
        // This means it must match the username entered on the next form field screen.
        //        dispatch({ type: SET_STUDENT_RESET, payload: true });
        //        history.push({ pathname: '/code/confirm' });
        // For Ticket 714. New Login - Block user from creating account if registration code is invalid .
        // If registration code returns NOT_FOUND, reject with appropriate error message.
        dispatch({ type: SET_SPINNER_VISIBLE, payload: false });
        setState({ ...state, valid: false, err: 'This code is not valid.' });
      } else {
        dispatch({ type: SET_CODE, payload: state.regcode });
        dispatch({ type: SET_STUDENT_RESET, payload: false });
        dispatch({ type: SET_ORDER, payload: res });
        dispatch({ type: SET_IS_TEACHER_EDITION, payload: createTeacher(state.regcode, res) });
        dispatch({ type: SET_CREATE_STUDENT_ON_FLY, payload: createStudentOnFly(res) });
        dispatch({ type: SET_IS_STUDENT_REGISTRATION_CODE, payload: !!res.course_id });
        dispatch({ type: SET_COURSE_ID, payload: res.course_id });
        dispatch({ type: SET_COURSE_NAME, payload: res.course_name });

        // Eugene added new course.school_id property to payload from /order/order.php/regicode/[code].
        // This precludes lookup by district instead of school within district.
        // It's expected that course.school_id will be the correct school_id in each of these three cases:
        // a) it's the school_id of a stand-alone school,
        // b) it's the school_id of a school that belongs to a district,
        // c) it is, itself, a district.
        // However, store the value in local variable school_id to make it easier to conditionally adjust.
        // OK, so apparently there are circumstances in which the course.school_id is null. If so,
        // use s_id.
        let school_id = res['course.school_id'] || res.s_id;
        getSchoolInfoById(school_id).then(res => {
          dispatch({ type: SET_SCHOOL, payload: res });
          dispatch({ type: SET_SPINNER_VISIBLE, payload: false });
          history.push({ pathname: '/code/confirm' });
        },
          (error) => {
            console.log(error);
            dispatch({ type: SET_SPINNER_VISIBLE, payload: false });
            history.push('/code/confirm');
          });
      }
    });
  }

  function updateRegcode(e) {
    var regcode = e.target.value;
    setState({ ...state, regcode });
  }

  function checkEmpty(focused) {
    var focus = focused;
    var valid = focus || !focus && state.regcode;
    var err = !valid ? 'Error: Please enter a valid code' : '';
    var status = (focused) ? state.status = '' : state.status;
    setState({ ...state, focus, valid, err, status });
  }

  function selectView() {
    var jsx;
    switch (state.view) {
      case 'regcode':
        jsx = (
          <div className="form-group">
            {!state.valid && <div className="validation-message margin-top-5px"> <span aria-live="polite">{state.err}</span></div>}
            <label className="font-weight-normal" htmlFor="username">Enter Your Code:</label>
            <input ref={regcodeField} value={state.regcode} onChange={updateRegcode} name="regcode" type="text" className="form-control border-width-1px" id="regcode" onFocus={() => { checkEmpty(true); }} onBlur={() => { checkEmpty(false); }} placeholder="Example: 12345ABCDE" />

            {!state.focus && !state.valid && state.touched && <span className="input-error pull-right glyphicon glyphicon-remove"></span>}
            {state.valid && state.regcode && <span className="input-valid pull-right glyphicon glyphicon-ok"></span>}
            <div className="fix-bottom">
              <button type="button" onClick={handleBack} className="btn accessible-btn-default">Back</button>
              {(!state.valid || !state.regcode) && <button disabled="disabled" onClick={handleNext} type="submit" className="btn accessible-btn-primary pull-right">Next</button>}
              {(state.valid && state.regcode) && <button onClick={handleNext} type="submit" className="btn accessible-btn-primary pull-right">Next</button>}
            </div>
          </div>
        );
        break;
      default:
    }
    return jsx;
  }

  return (
    <main>
      <form onSubmit={handleNext}>
        {selectView()}
      </form>
    </main>
  );
}

export default withRouter(RegCode);
