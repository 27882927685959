const USE_NEW_BACKEND_SERVER = true;
const NEW_BACKEND_PROD = true;

var w = window.location.hostname;
var uiHost = w;
if (/^www/.test(w) && USE_NEW_BACKEND_SERVER) {
  w = 'prod-api.perfectionnext.com';
}

var p = 'https:';
var t = window.location.port;
var query = window.location.search.substr(1);

function which_qa(host) {
  if (/qa3/.test(host)) {
    return 'qa3';
  } else if (/qa2/.test(host)) {
    return 'qa2';
  } else if (/qa1/.test(host)) {
    return 'qa1';
  } else if (/qa0/.test(host)) {
    return 'qa0';
  } else if (/samples/.test(host)) {
    return 'samples';
  } else {
    return '';
  }
}

const QA = which_qa(w);

const LOCALHOST = 'qa3.perfectionnext.com';

const HOST_MAP = {
  base: { local: 'qa3.perfectionnext.com', production: w },
  rest: { local: 'test-ohw.perfectionnext.com', production: w },
  websocket: { local: 'test-ohw.perfectionnext.com', production: 'test-ohw.perfectionnext.com' },
  login: { local: 'qa3.perfectionnext.com', production: w },
  order: { local: 'qa3.perfectionnext.com', production: w },
  next: { local: QA + 'perfectionnext.com', production: uiHost },
  nextReact: { local: QA + 'perfectionnext.com', production: w },
  canvasUi: { local: QA + 'perfectionnext.com', production: w },
  default: { local: QA + '.perfectionnext.com', production: w },
  backendQA: { local: QA + 'perfectionnext.com', production: w },
  backendProd: { local: QA + 'perfectionnext.com', production: w },
  teacherApp: { local: QA + 'perfectionnext.com', production: w },
  trialTest: { local: 'test-ohw.perfectionnext.com', production: w },
  trial: {
    local: 'test.perfectionnext.com',
    qa: 'test.perfectionnext.com',
    production: 'www.perfectionnext.com' //  http://www.perfectionnext.com/trial/trialendpt.php/username'
  }
};

const URI_MAP = {
  base: { local: 'api/endpoint/', production: 'api/endpoint/' },
  rest: { local: 'api/rest/', production: 'api/rest/' },
  websocket: { local: 'rest/webhook_endpoint.php/', production: 'rest/webhook_endpoint.php/' },
  login: { local: 'rest/', production: 'api/login/' },
  order: { local: 'api/order/', production: 'api/order/' },
  next: { local: 'next/assessment-app/dashboard/index.html', production: 'next/assessment-app/dashboard/index.html' },
  nextReact: { local: 'next-react/assessment-app/dashboard/index.html', production: 'next-react/assessment-app/dashboard/index.html' },
  canvasUi: { local: 'canvas-ui/assessment-app/dashboard/index.html', production: 'canvas-ui/assessment-app/dashboard/index.html' },
  default: { local: 'next/assessment-app/dashboard/index.html', production: 'next/assessment-app/dashboard/index.html' },
  backendQA: { local: 'backendqa/assessment-app/dashboard/index.html', production: 'backendqa/assessment-app/dashboard/index.html' },
  backendProd: { local: 'backendprod/assessment-app/dashboard/index.html', production: 'backendprod/assessment-app/dashboard/index.html' },
  teacherApp: { local: '', production: '' },
  trialTest: { local: 'rest/test_trial/trial/trialendpt.php/', production: 'api/trial/' },
  trial: { local: 'trial/trialendpt.php/', qa: 'trial/trialendpt.php/', production: 'trial/trialendpt.php/' }
};

function adjustBackend(url) {
  url = url.replace(/api\/rest/, 'rest/rest.php');
  url = url.replace(/api\/endpoint/, 'rest/endpoint.php');
  url = url.replace(/api\/login/, 'login');

  if (NEW_BACKEND_PROD) {
    url = url.replace(/www/, 'prod-api');  
  } else {
    url = url.replace(/qa\d/, 'qa-api');  
  }
  return url;
}

function set(type) {
  var url;
  if (w === 'localhost') {
    url = `${p}//${HOST_MAP[type].local}/${URI_MAP[type].local}`;
  } else {
    if (w.indexOf('qa') > -1 && type === 'trial') {
      url = `${p}//${HOST_MAP[type].qa}/${URI_MAP[type].qa}`;
    } else {
      url = `${p}//${HOST_MAP[type].production}/${URI_MAP[type].production}`;
    }
  }
  // REALLY BAD! ESPECIALLY WITH THE EXCEPTION FOR 'next'. HAVE TO TAKE ANOTHER APPROACH WHEN NOT SO RUSHED.
  if (USE_NEW_BACKEND_SERVER && type !== 'next') {
    url = adjustBackend(url);
    console.log('====> adjusted url', url);
  }


  return url;
}

function init() {
  var urls = {};
  for (let key in URI_MAP) { urls[key] = set(key); }
  return urls;
}

/* Configuration */
const REDIR_DOMAIN = {
  'qa0parents.perfectionnext.com': 'qa0.perfectionnext.com',
  'qa1parents.perfectionnext.com': 'qa1.perfectionnext.com',
  'qa2parents.perfectionnext.com': 'qa2.perfectionnext.com',
  'qa3parents.perfectionnext.com': 'qa3.perfectionnext.com',
  'parents.perfectionnext.com': 'www.perfectionnext.com',
};
const DOMAIN = window.location.host;
const USER = 'limited_parents@plconline.com';
const PW = 'Perfection1';
const LOGIN = 'https://' + REDIR_DOMAIN[DOMAIN] + '/api/login/users/login';
const REDIR = 'https://' + REDIR_DOMAIN[DOMAIN] + '/books/assessment-app/dashboard/index.html';
const isDBTS = /dbtsdigital\.(com|cn)/.test(window.location.href);
const isSample = /samples\.perfectionnext\.com/.test(window.location.href);

const REGEX_PASSWORD = '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!#$%&\'()*+,-.\/:;<=>\?@\\[\\]^_`{\|}~\"]{6,}$'
const PASSWORD_ERROR_MATCH = `Passwords must match.`;
const PASSWORD_ERROR_INVALID = `Passwords must be at least 6 characters long, and include a lowercase letter, uppercase letter and number.`;
const ERROR_PLACEHOLDER_CLASS = 'error-placeholder';

export { init, isDBTS, isSample, REGEX_PASSWORD, PASSWORD_ERROR_MATCH, PASSWORD_ERROR_INVALID, ERROR_PLACEHOLDER_CLASS };
