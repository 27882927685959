import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { API } from './helpers/api';
import * as serviceWorker from './serviceWorker';

function renderLogin() {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter basename={'/auth'}>
      <App />
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

function redirectToLanding(res) {
  // Check for location bar reference to assignment generator. If found, redirect to there using the teacher-app subdomain.
  var current = window.location.href;
  console.log('Redirect to landing', res);
  if (/assignment\-generator/.test(current)) {
    let url = current.replace(/qa1.perfection/, 'qa1teacher-app.perfection');
    console.log('Redirect to', url);
    window.location.href = url;
  } else {
    console.log('Redirect to', res.url);
    window.location.href = res.url;
  }
}

API.wrap_output().then(() => {
  API.user_status().then(
    (res) => {
      if (res.Code === 'NOT_LOGGED_IN') {
        renderLogin();
      }
      else {
        // logged in, so redirect.
        console.log('Standalone Login route_to_interface');
        API.route_to_interface().then(redirectToLanding);
      }
    },
    renderLogin
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
