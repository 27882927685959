import React, { useState, useContext, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { SET_SPINNER_VISIBLE, SET_EMAIL, SET_PASSWORD } from '../reducer';
import { Context } from '../Store';
import { getTrialCode, getTrialInfo, userCreate } from '../helpers/auth';

function Trial() {
  var history = useHistory();
  const [appState, dispatch] = useContext(Context);
  const [state, setState] = useState();

  const trialRe = /trial(\/(\w+))?$/;
  useEffect(() => {
console.log('history', history);
    let url = history.location.pathname.replace(/\/$/, '');
    let result = trialRe.exec(url);
    var trialType = result[2] ? result[2] : 'math';
console.log('trialType', trialType);
    trialLogin(trialType);
    
  }, []);


  function trialLogin(type) {
    console.log('in trial login', type);
    var email, password, token, code;
    getTrialCode(type).then(
      (response) => {
console.log('after getTrialCode response', response);
        code = response;
//        Cookie.set('hadTrial', 'true');
        dispatch({ type: SET_SPINNER_VISIBLE, payload: true });
        getTrialInfo().then(
          (response) => {
          // student is usertype 1
          email = response.email;
          password = response.pw;
          dispatch({ type: SET_EMAIL, payload: email });
          dispatch({ type: SET_PASSWORD, payload: password });
          var payload = {
            first: response.first_name,
            last: response.last_name,
            email: response.email,
            usertype: 1,
            pw: response.pw,
            token: code || '7837382543'
          };
          userCreate(payload).then(
            (response) => {
              history.push({ pathname: '/user/complete', state: { auto: true } });
            }, (err) => {
              history.push({ pathname: '/user/complete', state: { auto: true } });
            }
            );
          }
        );
      }
    );
  }

  return (
<form>
  <div></div>
</form>
  );
}

export default Trial;
