import React, { createContext, useReducer } from 'react';
import Reducer from './reducer';

const initialState = {
  username: '',
  email: '',
  password: '',
  autologout: false,
  studentReset: false,
  code: '',
  order: {},
  isTeacherEdition: false,
  createStudentOnFly: false,
  isStudentRegistrationCode: false,
  addToAccount: false,
  courseId: null,
  courseName: null,
  school: '',
  userCreate: {},
  addToAccount: false,
  userId: '',
  securityAnswer1: '',
  securityAnswer2: '',
  fromRedirect: 'engage',
  spinnerVisible: false,
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>
      { children }
    </Context.Provider>
  );
}

export const Context = createContext(initialState);
export default Store;
