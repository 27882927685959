/*
 HTTP functions
 */
function httpReady(resolve, reject) {
  return function() {
    if (this.readyState = 4 && this.response) {
      if (this.status === 200) {
        resolve(this.response);
      } else {
        reject({ status: this.status, response: this.response });
      }
    }
  }
};

function formatResponse(res) {
  var formatted = '';
  try {
    formatted = JSON.parse(res);
    if (formatted.data && formatted.status) {
      formatted = formatted.data;
    }
  } catch(e) {
    console.log('Problem with JSON.parse', res);
  }
  return formatted;
}

function httpPut(url, payload) {
  return new Promise((resolve, reject) => {
    const http = new XMLHttpRequest();
    http.withCredentials = true;
    http.open('PUT', url, true);
    http.setRequestHeader('Content-type', 'application/json');
    http.send(JSON.stringify(payload));
    http.onreadystatechange = function() {
      if (this.readyState === 4) {
        if (this.status === 200) {
          let response = formatResponse(this.response);
          resolve(response);
        } else {
          reject({ status: this.status, response: this.response });
        }
      }
    }
  });
}

function httpPost(url, payload) {
  return new Promise((resolve, reject) => {
    const http = new XMLHttpRequest();
    http.withCredentials = true;
    http.open('POST', url, true);
    http.setRequestHeader('Content-type', 'application/json');
    http.send(JSON.stringify(payload));
    console.log('====> httpPost', url);
    http.onreadystatechange = function() {
      if (this.readyState === 4) {
        if (this.status === 200) {
          let response = formatResponse(this.response);
          console.log('====> httpPost respond', url);
          resolve(response);
        } else {
          console.log('====> httpPost reject', url);
          reject({ status: this.status, response: this.response });
        }
      }
    }
  });
}

function httpGet(url, credentialsOff) {
  return new Promise((resolve, reject) => {
    const http = new XMLHttpRequest();
    if (!credentialsOff) http.withCredentials = true;
    http.open('GET', url, true);
    http.setRequestHeader('Content-type', 'application/json');
    http.send();
    http.onreadystatechange = function() {
      if (this.readyState === 4) {
        if (this.status === 200) {
          let response = formatResponse(this.response);
          resolve(response);
        } else {
          reject({ status: this.status, response: this.response });
        }
      }
    }
  });
}

export { httpGet, httpPost, httpPut };
