import React, { useState, useContext, useEffect, useRef } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { resetPassword, changePasswordWithToken, login, addToAccount, pushConcurrent } from '../helpers/auth';
import { SET_SPINNER_VISIBLE } from '../reducer';
import { Context } from '../Store';
import { REGEX_PASSWORD, PASSWORD_ERROR_MATCH, PASSWORD_ERROR_INVALID } from '../helpers/config';

function PasswordReset(props) {
	var history = useHistory();
	const [appState, dispatch] = useContext(Context);
	const [state, setState] = useState({ err: '', password: '', confirmPassword: '', buttonText: 'Submit' });
        const [token, setToken] = useState();
        

	useEffect(() => {
		const locationParts = window.location.href.split('/');
		const _token = locationParts[locationParts.length - 1];
		setToken(_token);
		passwordField.current.focus();
	}, []);

	const passwordField = useRef();

	function resetError(e) {
console.log('Reset error message.');
		setState({ ...state, err: '' });
	}


	function handleSubmit(e) {
		e.preventDefault();
		console.log('Submit', state.password, token);
		const payload = {
			pw: state.password,
			token
		};
		changePasswordWithToken(payload).then(res => {
			console.log('changed password; res', res);
      // Changing button text to "Done" on password change. However, there doesn't seem to be much point, as the browser immediately navigates to the login page.
			setState({ ...state, err: 'Password successfully changed.', buttonText: 'Done' });
			history.push('/');
		}).catch(res => {
			console.log('error with password change', res);
			if (res.response === 'invalid token') {
				let message = 'The link you clicked to set a new password is no longer valid.';
				setState({ ...state, err: message});
			} else {
				setState({ ...state, err: res.response });
			}
		});
	}

	// Maintain username as it's entered
	function updatePassword(e) {
		const { id, value } = e.target;
    let reTest = new RegExp(REGEX_PASSWORD);

		if (id === 'password') {
      let valid = false;
      if (reTest.test(value) && reTest.test(state.confirmPassword) && value === state.confirmPassword) {
        valid = true;
      }
			setState({ ...state, password: value, valid, err: '' });

		} else if (id === 'confirm-password') {
      let valid = false;
      if (reTest.test(value) && reTest.test(state.password) && value === state.password) {
        valid = true;
      }
			setState({ ...state, confirmPassword: value, valid, err: ''});
		}

	}


  function checkPassword(e) {
    const { id, value } = e.target;
    let reTest = new RegExp(REGEX_PASSWORD);
    let validPassword = reTest.test(value);

    // If the user leaves the New Password field, and the value isn't a valid password, set the state to false.
    if (id === 'password' && !reTest.test(value)) {
      setState({ ...state, valid: false, err: PASSWORD_ERROR_INVALID});
      return;
    }

    if (!validPassword) {
      setState({ ...state, valid: false, err: PASSWORD_ERROR_INVALID});
    }
    else if (state.password !== state.confirmPassword) {
      // If passwords don't match, don't display error if one of them is an empty string.
      let err = state.password.length > 0 && state.confirmPassword.length > 0 ? PASSWORD_ERROR_MATCH : '';
      setState({ ...state, valid: false, err });
    }
    else {
      setState({ ...state, valid: true, error: ''});
    }

  }

  function selectView() {
    var jsx;

    jsx = (
      <div className="form-group">
        <h4 tabIndex="0">Password Reset</h4>

        <div className="error-message">
          <div className="validation-message">
            <span aria-live="polite">{state.err}</span>
          </div>
        </div>

        <div>
        <label className="font-weight-normal" htmlFor="password">New Password:</label>
        <input
          ref={passwordField}
          onChange={updatePassword}
          onFocus={resetError}
          onBlur={checkPassword}
          name="password"
          type="password"
          value={state.password}
          className="form-control border-width-1px"
          id="password"
          placeholder="Enter your new password"
          />
        </div>

        <div>
        <label className="font-weight-normal" htmlFor="confirm-password">Confirm New Password:</label>
        <input
          onChange={updatePassword}
          onBlur={checkPassword}
          name="confirm-password"
          type="password"
          className="form-control border-width-1px"
          id="confirm-password"
          placeholder="Confirm your new password"
          />
        </div>

        <div className="fix-bottom">
          { (state.err != '' || !state.valid) && <button onClick={handleSubmit} disabled="disabled" type="submit" className="btn accessible-btn-primary pull-right">{state.buttonText}</button> }
          { (state.err == '' && state.valid) && <button onClick={handleSubmit} type="submit" className="btn accessible-btn-primary pull-right">{state.buttonText}</button>}
        </div>
      </div>
        );

    return jsx;
  }

  return (
      <main>
        <form onSubmit={handleSubmit}>
        <input type="hidden" id="token" defaultValue={token} />
        {selectView()}
        </form>
      </main>
  );
}

export default withRouter(PasswordReset);
