import React, { useState, useContext, useEffect, useRef } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Context } from '../Store';

function CodeConfirm() {
  var history = useHistory();
  const [appState, dispatch] = useContext(Context);
  if (appState.createStudentOnFly) {
    var codeMessage = 'This code gives you access to';
  } else {
    codeMessage = 'This code gives you teacher access for use at';
  }
  // Check for de Bono Thinking Systems domain; use their customer service email.
  const csemail = (/dbtsdigital\.(com|cn)/.test(window.location.href)) ? 'customerservice@debonosystems.com' : 'support@perfectionlearning.com';

  var noSeats = parseInt(appState.order.remaining_seats, 10) < 1;
  var error = false;

  const [state, setState] = useState({ code: appState.code, isStudentReset: appState.studentReset, order: appState.order, courseId: appState.courseId, courseName: appState.courseName, isTeacherEdition: appState.isTeacherEdition, createStudentOnFly: appState.createStudentOnFly, error, codeMessage, csemail, noSeats });

  if (!state.isStudentReset) {
    error = (!state.book_name);
  }

  // Focus input field on load.
  useEffect(() => {
    if (msg && msg.current) msg.current.focus();
    if (!state.isStudentReset) {
      setState({ ...state, order: appState.order, courseId: appState.courseId, courseName: appState.courseName, isTeacherEdition: appState.isTeacherEdition, createStudentOnFly: appState.createStudentOnFly });
    // 2019-03-20. From https://trello.com/c/yjjFaGFa/8-is-this-the-message-we-want-to-give-users-when-they-enter-a-code
    // Remove "student" from the registration if it is a student registration.
    // Formerly userType; however, since the message text began to diverge, we now set
    // codeMessage, depending on whether or not the createStudentOnFly flag is set.
      if (appState.createStudentOnFly) {
        setState({ ...state, codeMessage: 'This code gives you access to' });
      } else {
        setState({ ...state, codeMessage: 'This code gives you teacher access for use at' });
      }
    }
  }, []);

  const msg = useRef();

  const handleBack = (e) => {
    history.push('/code');
  };

  const handleNext = (e) => {
    // Teachers and any admins can purchase TE products.
    // We don't allow students to purchase a TE product.
    // [...a later development...]
    // We do, however, allow students to be created on the fly in certain cases. (See auth.code.components.)
    // For DBTS codes, the is_te flag is false, and createStudentOnFly is true; so: student registration + email.
    if (appState.isTeacherEdition || appState.createStudentOnFly) {
      history.push('/user/email'); // create-teacher']);
    } else if (appState.isStudentReset || !appState.isTeacherEdition) {
      history.push('/user/username');
    } else {
      setState({ ...state, error: true });
      // this.router.navigate(['/auth/user/username']); // create-student']);
    }
  };

  function selectView() {
    var jsx;
    jsx = (
<div className="text-center">

	<h3><strong>Code: {state.code}</strong></h3>

	<br/>

	{ state.noSeats && !state.isStudentReset && (<div>
		<h4 ref={msg} className="wcag-err" tabIndex="0" aria-live="polite">Sorry, all seats have been taken.<br/>Please try another code!</h4>
	</div>) }

	{ !state.noSeats && !state.isStudentReset && appState.order.book_name && (<div className="margin-top-5px">
		<h4 ref={msg} className="wcag-msg" tabIndex="0" aria-live="polite">
			{state.codeMessage}<br/>{appState.school.name}
		</h4>
	</div>) }

	{ state.isStudentReset && (<div>
		<h4 ref={msg} className="wcag-msg" tabIndex="0" aria-live="polite">Please confirm that this is your code. If it is, click Next.</h4>
	</div>) }

	{ state.error && (<div>
		<h4 ref={msg} className="wcag-err" tabIndex="1" aria-live="polite">Error: Sorry, we didn&apos;t recognize that code. Please try again.</h4>
	</div>) }

	<div className="fix-bottom">
		<div className="margin-top-5px small">
			<span className="wcag-msg" tabIndex="2" aria-live="polite">If this is incorrect, contact us at <a href="mailto:{state.csemail}?Subject=Hello%20again" target="_top">{state.csemail}</a>.</span>
		</div>
		<br/>
		<button onClick={handleBack} type="button" className="pull-left btn accessible-btn-default">Back</button>
		{ (state.error || state.noSeats) && <button disabled={true} type="submit" onClick={handleNext} className="pull-right btn accessible-btn-primary">Next</button> }
		{ (!state.error && !state.noSeats) && <button disabled={false} type="submit" onClick={handleNext} className="pull-right btn accessible-btn-primary">Next</button> }
	</div>

</div>

    );
    return jsx;
  }

  return (
      <main>
        <form onSubmit={handleNext}>
        { selectView() }
        </form>
      </main>
  );
}

export default CodeConfirm;
