import React, { useState, useContext, useEffect, useRef } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Context } from '../Store';
import { SET_PASSWORD } from '../reducer';
import { userCreate } from '../helpers/auth';
import { REGEX_PASSWORD } from '../helpers/config';

function Create() {
  var history = useHistory();
  const [appState, dispatch] = useContext(Context);
  const [state, setState] = useState({ view: 'initial', firstName: '', lastName: '', password: '', repeatPassword: '' });

  // Focus input field on load.
  useEffect(() => {
    switch(state.view) {
      case 'firstName':
        firstNameField.current.focus();
        break;

      case 'lastName':
        lastNameField.current.focus();
        break;

      case 'password':
        passwordField.current.focus();
        break;

      case 'repeatPassword':
        repeatPasswordField.current.focus();
        break;

    }
  }, [state.view]);

  var lastNameField = useRef('lastNameField');
  var passwordField = useRef('passwordField');
  var repeatPasswordField = useRef('repeatPasswordField');
  var firstNameField = useRef('firstNameField');

  const updateFirstName = (e) => {
    let el = e.currentTarget;
    setState({ ...state, firstName: el.value });
console.log('updateFirstName', el.value);
  }

  const updateLastName = (e) => {
    let el = e.currentTarget;
    setState({ ...state, lastName: el.value });
  }

  const updatePassword = (e) => {
    let el = e.currentTarget;
    setState({ ...state, password: el.value });
  }

  const updateRepeatPassword = (e) => {
    let el = e.currentTarget;
    setState({ ...state, repeatPassword: el.value });
  }

  const checkPassword = () => {
    let passwordRe = new RegExp(REGEX_PASSWORD);
    let valid = passwordRe.test(state.password);
    setState({ ...state, valid });
  }


  const handleBack = (e) => {
    e.preventDefault();
    history.push('/code');
  };

  const handleNext = (e) => {
    e.preventDefault();
    dispatch({ type: SET_PASSWORD, payload: state.password });
    var payload = {
      first: state.firstName,
      last: state.lastName,
      email: appState.email,
      pw: state.password,
      token: appState.code,
      wrap_output: true
    };
console.log('payload to userCreate', payload);
    userCreate(payload)
      .then(res => {
console.log('userCreate response', res);
        history.push({ pathname: '/user/complete', state: { auto: true } });
      });
  };

  const checkEmpty = (focused, field) => {
    let focus = focused;
    let valid = focus || !focus && state[field];
console.log('checkEmpty valid', field, valid);
    setState({ ...state, focus, valid });
  }

  function selectView() {
    var view = state.view;
    var jsx;
    switch (view) {

      case 'initial':
        jsx = (
          <div>
			<div className="text-center">
				<h4 className="wcag-msg" tabIndex="0">Ok, we'll create a new account for you using the email address you supplied:<br/>{appState.email}</h4>
			</div>
			<div className="fix-bottom">
				<button onClick={handleBack}type="button" className="btn accessible-btn-default">Back</button>
				<button
					onClick={(e) => { e.preventDefault(); setState({ ...state, view: 'firstName' }); }}
					type="submit"
					className="btn accessible-btn-primary pull-right">Next</button>
			</div>
          </div>
        );
        break;

      case 'firstName':
        jsx = (
          <div>
			<div className="form-group">
				<label className="font-weight-normal" htmlFor="firstName">First Name:</label>
				<div>
					<input
                        ref={firstNameField}
						value={state.firstName}
                        onChange={updateFirstName}
						type="text"
						id="firstName"
						className="form-control border-width-1px"
						name="firstName"
						pattern="^[a-zA-Z0-9 ,.'-]+$"
						maxLength="60"
						onFocus={() => { checkEmpty(true, 'firstName'); }}
						onBlur={() => { checkEmpty(false, 'firstName'); }}
						placeholder="Enter your first name" />
					{ !state.valid && state.firstNameTouched && <span className="input-error pull-right glyphicon glyphicon-remove"></span> }
					{ state.valid && state.firstName && <span className="input-valid pull-right glyphicon glyphicon-ok"></span> }
				</div>
			</div>
			{ !state.valid && state.firstNameTouched && (<div className="validation-message">
				<span aria-live="polite">Error: Names must be less than 60 characters and use only alphanumerics with commas, periods, apostrophes and hyphens.</span>
			</div>)}
			<div className="fix-bottom">
				<button onClick={handleBack} type="button" className="btn accessible-btn-default">Back</button>
				{ (!state.valid || !state.firstName) && <button disabled={true} type="submit" className="btn accessible-btn-primary pull-right">Next</button> }
				{ (state.valid && state.firstName) && <button onClick={(e) => { e.preventDefault(); setState({ ...state, view: 'lastName'}); }} type="submit" className="btn accessible-btn-primary pull-right">Next</button> }
			</div>
          </div>
        );
        break;

      case 'lastName':
        jsx = (
          <div>
			<div className="form-group">
				<label className="font-weight-normal" htmlFor="lastName">Last Name:</label>
				<input
                    ref={lastNameField}
					value={state.lastName}
                    onChange={updateLastName}
					id="lastName"
					type="text"
					maxLength="60"
					className="form-control border-width-1px"
					name="lastName"
					onFocus={() => { checkEmpty(true, 'lastName'); }}
					onBlur={() => { checkEmpty(false, 'lastName'); }}
					placeholder="Enter your last name" />
				{ !state.valid && state.lastNameTouched && <span className="input-error pull-right glyphicon glyphicon-remove"></span> }
				{ state.valid && state.lastName && <span className="input-valid pull-right glyphicon glyphicon-ok"></span> }
			</div>
			{ !state.valid && state.lastNameTouched && (<div className="validation-message">
				<span aria-live="polite">Error: Names must be less than 60 characters and use only alphanumerics with commas, periods, apostrophes and hyphens.</span>
			</div>) }
			<div className="fix-bottom">
				<button onClick={e => { e.preventDefault(); setState({ ...state, view: 'firstName' }); }} type="button" className="btn accessible-btn-default">Back</button>
                { (!state.valid || !state.lastName) && <button onClick={(e) => { e.preventDefault(); setState({ ...state, view: 'password'}); }} type="submit" className="btn accessible-btn-primary pull-right">Next</button> }
                { (state.valid && state.lastName) && <button onClick={(e) => { e.preventDefault(); setState({ ...state, view: 'password'}); }} type="submit" className="btn accessible-btn-primary pull-right">Next</button> }
			</div>
          </div>
        );
        break;

      case 'password':
        jsx = (
          <div>
			<div className="form-group">
				<label className="font-weight-normal" htmlFor="createPassword">Password:</label>
				<input
                    ref={passwordField}
					value={state.password}
					onChange={updatePassword}
					id="password"
					type="password"
					className="form-control border-width-1px"
					name="password"
					onFocus={() => { checkEmpty(true, 'password'); }}
					onBlur={() => { checkEmpty(false, 'password'); }}
					onKeyUp={checkPassword}
					placeholder="Create your password" />
				{ !state.focus && !state.valid && state.passwordTouched && <span className="input-error pull-right glyphicon glyphicon-remove"></span> }
				{ state.valid && state.password && <span className="input-valid pull-right glyphicon glyphicon-ok"></span> }
			</div>
			<div className="alert alert-info">
				<span aria-live="polite">Passwords must be at least 6 characters long, and include a lowercase letter, uppercase letter and number.</span>
			</div>
			{ !state.focus && !state.valid && state.passwordTouched && (<div className="validation-message">
				<span aria-live="polite">Error: Passwords must meet the requirements above.</span>
			</div>) }

			<div className="fix-bottom">
				<button onClick={e => { e.preventDefault(); setState({ ...state, view: 'lastName' }); }} type="button" className="btn accessible-btn-default">Back</button>
				{ (!state.valid || !state.password) && (<button disabled={true} 
					type="submit" className="btn accessible-btn-primary pull-right">Next</button>) }
				{ (state.valid && state.password) && (<button disabled={false} 
					onClick={e => { e.preventDefault(); setState({ ...state, view: 'repeatPassword' }); }}
					type="submit" className="btn accessible-btn-primary pull-right">Next</button>) }
			</div>
          </div>
        );
        break;

      case 'repeatPassword':
        jsx = (
          <div>
			<div className="form-group">
				<label className="font-weight-normal" htmlFor="createPassword">Repeat Password:</label>
				<input
                    ref={repeatPasswordField}
					value={state.repeatPassword}
                    onChange={updateRepeatPassword}
					type="password"
					id="repeatPassword"
					className="form-control border-width-1px"
					name="repeatPassword"
					onFocus={() => { checkEmpty(true, 'repeatPassword'); } }
					onBlur={() => { checkEmpty(false, 'repeatPassword'); } }
					onKeyUp={checkPassword}
					placeholder="Re-enter your password" />
				{ !state.focus && state.password !== state.repeatPassword && (<span
					className="input-error pull-right glyphicon glyphicon-remove"></span>) }
				{ state.repeatPassword && (state.password === state.repeatPassword) && (<span
					className="input-valid pull-right glyphicon glyphicon-ok"></span>) }
				{ !state.focus && (state.password !== state.repeatPassword) && (<div className="validation-message">
					<span aria-live="polite">Error: Passwords don&apos;t match.</span>
				</div>) }
			</div>
			<div className="fix-bottom">
				<button onClick={e => { e.preventDefault(); setState({ ...state, view: 'lastName' }); }} type="button" className="btn accessible-btn-default">Back</button>
				{ (!state.valid || (state.password !== state.repeatPassword)) && <button disabled={true}  type="submit" className="btn accessible-btn-primary pull-right">Next</button>}
				{ state.valid && state.password === state.repeatPassword && <button type="submit" className="btn accessible-btn-primary pull-right">Next</button> }
			</div>
          </div>
        );


    }

    return jsx;
  }

  return ( 
      <main>
        <form onSubmit={handleNext}>
        { selectView() }
        </form>
      </main>
  );
}

export default Create;
