import React, { useState, useContext, useEffect, useRef } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Context } from '../Store';
import { login, routeToInterface } from '../helpers/auth';

function Completed() {
  var history = useHistory();
  var { auto } = history && history.location.state || {};
console.log('Completed, history', history);
  const [appState, dispatch] = useContext(Context);
  const [state, setState] = useState({ auto });
console.log('Completed, auto', state.auto);

  const handleBack = (e) => {
    e.preventDefault();
    history.push('/code');
  };

  const handleClick = (e) => {
    e && e.preventDefault();
    var username = appState.username || appState.email;
    var password = appState.password;
    var email = appState.email;
    var payload = { email: email || username, pw: password, wrap_output: true };
console.log('Complete; handleClick', username, password);
    login(username, password).then(
      (response) => {
        routeToInterface().then(
          (res) => {
console.log('Complete; handleClick logged in', res);
            window.location.href = res.url;
          },
          (error) => {
            console.log(`error${error}`);
          }
        );
      });
  }
  if (state.auto) {
    handleClick();
  }

  function selectView() {
    var jsx = state.auto ? <div></div> : (
    <form>
      <div className="text-center">
        <h4>Your account is now set up!<br/>Please click Go to proceed to your account.</h4>
      </div>
      <div className="fix-bottom">
        <button className="btn accessible-btn-primary pull-right" onClick={handleClick}>Go</button>
      </div>
    </form>
    );

    return jsx;
  }

  return ( 
      <main>
        { selectView() }
      </main>
  );
}

export default Completed;
