import React from 'react';
import './spinner.css';


function Spinner(props) {
  const { visible } = props;

  return (
    <div>
{ visible && (<div className="spinner">
  <div className="loader">
    <div className="spin"></div>
  </div>
</div>) }

{ !visible && (<div>
  <ng-content></ng-content>
</div>) }

    </div>
  );
}

export default Spinner;
