import React, { useState, useContext, useEffect, useRef } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { checkSecurityQuestions, resetPassword, login } from '..//helpers/auth';
import { Context } from '../Store';

const VALID_QUESTIONS = ['Who is your favorite teacher?', 'How many family members do you have?', 'What is your favorite food?'];


function Security() {
  const [appState, dispatch] = useContext(Context);
  const [state, setState] = useState({ num: 0, view: 'answer', questions: VALID_QUESTIONS, answers: [] });
  var history = useHistory();

  function reset() {
    state.selectedQuestion = state.questions[0];
    setState({ ...state, num: 0 });
    setState({ ...state, view: 'answer' });
  }

  function handleChange(e) {
    var el = e.target;
    var val = el.value;
    console.log('answer', val);
    var { num, answers } = state;
    answers[num] = val;
    setState({ ...state, answers });
  }

  function handleClick(e) {
    e.preventDefault();
    if (state.num === 0) {
      setState({ ...state, num: state.num + 1 });
      return;
    } else {
      var payload = { security_q_1: state.questions[0], security_q_2: state.questions[1], security_a_1: state.answers[0], security_a_2: state.answers[1] };
console.log('security handleClick', payload);
      checkSecurityQuestions(payload).then(
        (response) => {
          if (response.status === 'ACCOUNT_LOCKED') {
            setState({ view: 'errorTooManyAttempts' });
          } else if (response.data === false) {
            setState({ view: 'errorInvalidResponse' });
          } else {
	          // dispatch({ type: SET_SECURITY_ANSWERS, payload: state.answers });
	          history.push('/user/complete'); 
          }
        },
        (error) => {
          setState({ view: 'errorTooManyAttempts' });
        }
      );
    }
  }


  function selectView() {
    var jsx;
    switch (state.view) {
      case 'answer':
        jsx = (
		<div>
			<div className="form-group">
				<label className="font-weight-normal" htmlFor="securityAnswer">Answer the security questions to reset your password.<br/>
					<strong>{state.questions[state.num]}</strong>:</label>
				<input
					type="text"
					className="form-control border-width-1px"
					id="securityAnswer"
					pattern="[a-zA-Z0-9 ]+"
					placeholder="Enter your answer"
					required
                    onChange={handleChange}
					onFocus={() => setState({ ...state, focus: true })}
					onBlur={() => setState({ ...state, focus: false })}
					/>
				{ !state.focus && !state.valid && state.touched && <span className="input-error pull-right glyphicon glyphicon-remove"></span> }
				{ state.valid && <span className="input-valid pull-right glyphicon glyphicon-ok"></span> }
				{ !state.focus && !state.valid && state.touched && <div className="validation-message"> <span aria-live="polite">Error: Please enter the answer you supplied for this question.</span> </div> }
			</div>
			<div className="fix-bottom">
				<button type="button" onClick={() => history.push('/user/reset')} className="pull-left btn">Back</button>
				{ false && !state.valid && <button disabled="disabled" onClick={handleClick} type="submit" className="btn btn-primary pull-right">Next</button> }
				{ (true || state.valid) && <button onClick={handleClick} type="submit" className="btn btn-primary pull-right">Next</button> }
			</div>
		</div>
        );
        break;
      case 'errorTooManyAttempts':
        jsx = (
		<div>
			<div className="form-group">
				<div className="text-center">
					<h4 tabIndex="0" aria-live="polite">Error: Sorry, but you've exceed the maximum attempts to try answering your security questions. The answers to your security questions were invalid.<br/>An e-mail has been sent to your teacher notifying them. Please contact your teacher to reset your password.</h4>
				</div>
				<div className="fix-bottom">
					<button type="button" onClick={() => history.push('/')} className="pull-left btn">Back</button>
				</div>
			</div>
		</div>
        );
        break;
      case 'errorInvalidResponse':
        jsx = (
		<div>
			<div className="form-group">
				<div className="text-center">
					<h4 tabIndex="0" aria-live="polite">Error: Sorry, but the answers to your security questions were not correct. Please try again.</h4>
				</div>
				<div className="fix-bottom">
					<button type="button" onClick={reset} className="pull-left btn">Back</button>
				</div>
			</div>
		</div>
        );
        break;
      case 'errorNoQuestions':
        jsx = (
		<div>
			<div className="form-group">
				<div className="text-center">
					<h4 tabIndex="0" aria-live="polite">Error: Sorry, but there are no security questions set up on your account. Please contact your teacher to reset your password.</h4>
				</div>
				<div className="fix-bottom">
					<button type="button" onClick={history.push('/username')} className="pull-left btn">Back</button>
				</div>
			</div>
		</div>
        );
        break;

    }
    return jsx;
  }

  return (
    <form>
      {selectView()}
    </form>
  );
}

export default withRouter(Security);
