import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
const titleClassNames = ['bold', 'center', 'wcag-h2-mimic-h3'];


function ForgotPasswordResponse(props) {
  const { state } = useLocation();
  const email = state.email;
  const history = useHistory();

  function returnToLogin() {
    history.push('/');
  }

  function handleFocus(e) {

  }

  function handleBlur(e) {

  }

  return (
	<main>
      <form>

      <div className="form-group">
      <h2 className={titleClassNames.join(' ')}>Forgot Password</h2>

      <div className="text-center">
          <h4>If {email} corresponds to an account in our system, we sent a recovery link to it.</h4>

        </div>

        <div className="text-center">
          <div className="fix-bottom">
            <Link to={{ pathname: "/forgot-password", state: { email } }}>Resend recovery link</Link>
            &nbsp; &middot; &nbsp; 
            <Link to="/">Return to log in</Link>
            </div>
        </div>

      </div>

      </form>
	</main>
  );
}

export default ForgotPasswordResponse;
