import React, { useState, useContext, useEffect, useRef } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { login, resetStudentPassword, resetPassword } from '../helpers/auth';
import { SET_PASSWORD } from '../reducer';
import { Context } from '../Store';
import { REGEX_PASSWORD } from '../helpers/config';

function Reset(props) {
  var history = useHistory();
  const [appState, dispatch] = useContext(Context);
  const [state, setState] = useState({ token: appState.code, isForgotPassword: false, touched: false, err: '', view: '', status: '', valid: false, focus: false, focus2: false, password: '', repeatPassword: '' });

  var { username } = appState || {};

  useEffect(() => {
    if (state.token) {
      setView('password');
    } else {
      setView('confirmSendToEmail');
    }
    if (passwordField.current) passwordField.current.focus();
  }, []);

  const passwordField = useRef();

  function handleBack() {
    history.push('/login/password');
  }

  function setView(view) {
    setState({ ...state, view });
  }

  function sendToEmail() {
    var username = appState.username;
    var email = appState.email;
    resetPassword({ email: username || email, wrap_output: true }).then(
      (response) => {
        setState({ ...state, view: 'sent' });
      },
      (error) => {
        setState({ ...state, view: 'error' });
      });
  }

  function updateViaSecurityAnswers() {
  }

  function updateViaToken() {
  }

  function updateStudent() {
    var tempPassword = appState.code;
    var newPassword = state.password;
    var username = appState.username;
    var payload = { temp_pw: tempPassword, new_pw: newPassword, email: username };
    resetStudentPassword(payload).then(res => {
      dispatch({ type: SET_PASSWORD, payload: state.password });
      var payload = { email: username, pw: state.password, wrap_output: true };
      login(username, state.password).then(res => {
        // at this point authenticated as a student
        history.push('/user/security/create');
      },
      (error) => { console.log(`error${error}`); });
    },
    (error) => {
      setState({ ...state, view: 'errorUpdateStudent' });
    });
  }

  function updatePassword(e) {
    e.preventDefault();
    if (state.view === 'repeatPassword') {
      history.push('/user/security/create');
      updateStudent();
    } else {
      setState({ ...state, view: 'repeatPassword' });
    }
  }

  function checkPassword(e) {
    var id = e.target.id;
    var password = e.target.value;
    var newState = { ...state, touched: true, valid: false };
    if (id === 'repeatPassword') {
      newState.repeatPassword = password;
      if (password === state.password) {
        newState.valid = true;
      }
    } else {
      newState.password = password;
      let passwordRe = new RegExp(REGEX_PASSWORD);
      if (passwordRe.test(password)) {
        newState.valid = true;
      }
    }
    setState(newState);
  }

  function routerLink(link) {
    history.push(link);
  }

  function handleSubmit() {
  }

  function selectView() {
    var jsx;
    switch (state.view) {
      case 'password':
        jsx = (
    <div>
      <div className="form-group">
        <label className="font-weight-normal" htmlFor="createPassword">Enter Your New Password:</label>
        <input
          ref={passwordField}
          type="password"
          className="form-control border-width-1px"
          name="password"
          id="password"
          value={state.password}
          onChange={checkPassword}
          onFocus={() => setState({ ...state, focus: true }) }
          onBlur={() => setState({ ...state, focus: false }) }
          placeholder="Create your password"/>
        { !state.focus && !state.valid && state.touched && <span className="input-error pull-right glyphicon glyphicon-remove"></span> }
        { state.valid && <span className="input-valid pull-right glyphicon glyphicon-ok"></span> }
      </div>
      <div className="alert alert-info">
        <span aria-live="polite">Passwords must be at least 6 characters long, and include a lowercase letter, uppercase letter and number.</span>
      </div>
      <div className="fix-bottom">
        <button onClick={() => { routerLink('/main'); } } type="button" className="btn accessible-btn-default">Back</button>
        { !state.valid && <button disabled="disabled" className="btn accessible-btn-primary pull-right">Next</button> }
        { state.valid && <button onClick={updatePassword} className="btn accessible-btn-primary pull-right">Next</button> }
      </div>
    </div>
        );
        break;

      case 'repeatPassword':
        jsx = (
    <div>
      <div className="form-group">
        <label className="font-weight-normal" htmlFor="repeatPassword">Repeat Your New Password:</label>
        <input
          type="password"
          className="form-control border-width-1px"
          name="repeatPassword"
          id="repeatPassword"
          value={state.repeatPassword}
          onChange={checkPassword}
          onFocus={() => setState({ ...state, focus2: true }) }
          onBlur={() => setState({ ...state, focus2: false }) }
          placeholder="Re-enter your password" />
        { !state.focus2 && (state.password !== state.repeatPassword) && state.repeatPasswordTouched && <span className="input-error pull-right glyphicon glyphicon-remove"></span> }
        { state.password === state.repeatPassword && <span className="input-valid pull-right glyphicon glyphicon-ok"></span> }
        { !state.focus2 && (state.password !== state.repeatPassword) && state.repeatPasswordTouched && <div className="validation-message"> <span aria-live="polite">Error: Passwords don&apos;t match.</span> </div> }
      </div>
      <div className="fix-bottom">
        <button onClick={() => setView('password') } type="button" className="btn accessible-btn-default">Back</button>
        { (state.password !== state.repeatPassword) && <button disabled="disabled" type="submit" className="btn accessible-btn-primary pull-right">Next</button> }
        { (state.password === state.repeatPassword) && <button onClick={updatePassword} className="btn accessible-btn-primary pull-right">Next</button> }
      </div>
    </div>
        );
        break;

      case 'confirmSendToEmail':
        jsx = (
    <div>
      <div className="text-center">
        <h4 className="wcag-err" tabIndex="0" aria-live="polite">Forgot your password?<br/><br/>Click Next, and we&apos;ll send an email to you with a link to reset it.</h4>
      </div>
      <div className="fix-bottom">
        <button onClick={() => routerLink('/login/password')} type="button" className="btn accessible-btn-default">Back</button>
        <button onClick={sendToEmail} type="button" className="btn accessible-btn-primary pull-right">Next</button>
      </div>
    </div>
        );
        break;

      case 'sent':
        jsx = (
    <div>
      <div className="text-center">
        <h4 className="wcag-err" tabIndex="0" aria-live="polite">Please check your email for a password reset link and follow the enclosed instructions.</h4>
      </div>
    </div>
        );
        break;

      case 'error':
        jsx = (
    <div>
      <div className="text-center">
        <h4 className="wcag-err" tabIndex="0" aria-live="polite">We were unable to reset your password. Please try again.</h4>
      </div>
      <div className="fix-bottom">
        <button onClick={() => routerLink('/user/reset')} className="btn pull-left">Back</button>
        <button className="btn accessible-btn-primary pull-right" onClick={sendToEmail}>Resend Email</button>
      </div>
    </div>
        );
        break;

      case 'errorSecurityQuestions':
        jsx = (
    <div>
      <div className="text-center">
        <h4 className="wcag-err" tabIndex="0" aria-live="polite">Click Back to try again. If you do not remember the answers to your security questions, contact your teacher to reset your password.</h4>
      </div>
      <div className="fix-bottom">
        <button onClick={() => routerLink('/security')} className="btn pull-left">Back</button>
      </div>
    </div>
        );
        break;

      case 'errorUpdateStudent':
        jsx = (
    <div>
      <div className="text-center">
        <h4 className="wcag-err" tabIndex="0" aria-live="polite">Sorry, this code is incorrect. Please check your code and try again. If it still doesn&apos;t work, please contact your teacher.</h4>
      </div>
      <div className="fix-bottom">
        <button onClick={() => routerLink('/')} className="btn pull-left">Back</button>
      </div>
    </div>
        );
        break;
    };
    return jsx;
  }

  return (
      <form onSubmit={updatePassword}>
          {selectView()}
      </form>
  );
}

export default withRouter(Reset);
